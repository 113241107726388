.interview-current{
    width: calc(100%-40rem);
    margin: 6rem 20rem;
}

.interview-current .inteview-info{
    display: flex;
    flex-direction: row;
}
.interview-current .interview-info_links{
    display: flex;
    flex-direction: column;
}

/* ALL SOCIAL LINKS */
.interview-current a{
    text-decoration: none;
    font-size: 4rem;
    color: white;
}
.interview-current .inteview-info a{
    text-decoration: none;
    font-size: 3rem;
    color: rgb(34, 248, 248);
    transition: all .2s ease-in-out;
}

.interview-current .inteview-info a:hover{
    text-decoration: none;
    color:white;
}


.interview-current img{
    width: 20%;
    height: 20rem; 
    object-fit: cover;
    filter: grayscale(1);
    margin-bottom: 3rem;
    margin-right:1rem;
    box-shadow:5px 2px 20px 12px rgba(0,0,0,0.7)    
}

/* ALL HEADINGS */
.interview-current h1{
font-family: 'Staatliches', sans-serif;
font-size: 8rem;
letter-spacing: 2px;
}

.interview-current h2{
    font-family: 'Staatliches', sans-serif;
    font-size: 1.3rem;
    letter-spacing: 2px;
    margin-bottom: 2rem;
    }

    .interview-current h3{
        font-family:sans-serif;
        font-size: 1.2rem;
        font-weight: 800;
        margin-bottom: 3rem;
        color:rgb(34, 248, 248);
        }
        .interview-current h4{
            font-family:sans-serif;
            font-size: 1.5rem;
            font-weight: 800;
            margin-bottom: 2rem;
            color: white;
            }

            /* BACK LINK */
            .interview-current .back-link
            {
            position: relative;
            font-family:'Staatliches', sans-serif;
            font-size: 4rem;
            color:rgb(34, 248, 248); 
            padding: .6rem;
            padding-bottom: 0rem;
            border-radius: 50%;
            transition: all .2s ease-in-out;
        }

        .interview-current .back-link:hover
        {
            color: white;
        }

         /* BACK LINK after */
        .interview-current .back-link::after{
            content: 'RETURN';
            position: absolute;
            bottom: 20%;
            right: -5%;
            color:white ;
            font-family:'Staatliches', sans-serif;
            font-size: 1.2rem;
            transition: all .2s ease-in-out;
        }


        .interview-current .back-link:focus:after{
            color:rgb(34, 248, 248);
        }
        @media screen and (min-width: 1651px) {

        }
        
        @media screen and (max-width: 1650px) {
        
        }
        
        @media screen and (min-width: 1440px)and (max-width:1649px) {
            .interview-current img{
                width: 30%;
            }

        }        
        @media screen and (min-width: 1250px)and (max-width:1439px) {
            .interview-current img{
                width: 30%;
            }

        }
        @media screen and (min-width: 1024px)and (max-width:1249px) {
            .interview-current{
                width: calc(100%-20rem);
                margin: 6rem 10rem;
            }
            .interview-current img{
                width: 40%;
            }
            .interview-current h1{
                font-size: 6rem;
                }
                .interview-current h2{
                    font-size: 1.2rem;
                    }
                
                    .interview-current h3{
                        font-size: 1rem;
                        }
                        .interview-current h4{
                            font-size: 1.2rem;
                            }
        }
        @media all and (min-width: 768px) and (max-width: 1023px) {
            .interview-current{
                width: calc(100%-10rem);
                margin: 6rem 5rem;
            }
            .interview-current img{
                width: 50%;
                height: 15rem;
            }
            .interview-current h1{
                font-size: 6rem;
                }
                .interview-current h2{
                    font-size: 1.2rem;
                    }
                
                    .interview-current h3{
                        font-size: 1rem;
                        }
                        .interview-current h4{
                            font-size: 1.2rem;
                            }
                            .interview-current .inteview-info a{
                                font-size: 2rem;
                            }
        }

        @media all and (min-width: 480px) and (max-width: 767px) {
            .interview-current{
                width: calc(100%-10rem);
                margin: 6rem 5rem;
            }
            .interview-current img{
                width: 60%;
                height: 15rem;
            }
            .interview-current h1{
                font-size: 4.8rem;
                }
                .interview-current h2{
                    font-size: .8rem;
                    margin-bottom: 1rem;
                    }
                
                    .interview-current h3{
                        font-size: .8rem;
                        }
                        .interview-current h4{
                            font-size: 1rem;
                            }
                            .interview-current .inteview-info a{
                                font-size: 2rem;
                            }
                            .interview-current .back-link{
                                font-size: 3rem;
                            }
                            .interview-current .back-link::after{
                                font-size: 1rem;
                            }

        }

        @media all and (min-width: 280px) and (max-width: 479px) {
            .interview-current{
                width: calc(100%-2rem);
                margin: 2rem 1rem;
            }
            .interview-current img{
                width: 70%;
                height: 10rem;
            }
            .interview-current h1{
                font-size: 3.7rem;
                }
                .interview-current h2{
                    font-size: .7rem;
                    margin-bottom: 1rem;
                    }
                
                    .interview-current h3{
                        font-size: .6rem;
                        }
                        .interview-current h4{
                            font-size: .7rem;
                            }
                            .interview-current .inteview-info a{
                                font-size: 2rem;
                            }
                            .interview-current .back-link{
                                font-size: 2rem;
                            }
                            .interview-current .back-link::after{
                                font-size: .6rem;
                            }

        }
        
