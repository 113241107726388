.error-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
}  
.error-page h1 {
    font-family: "Staatliches", sans-serif;
    letter-spacing: 2px;
    font-size: 7rem;
    font-weight: 800;
    color: rgb(34, 248, 248);
}
.error-page h2 {
    font-family: "Staatliches", sans-serif;
    letter-spacing: 2px;
    font-size: 4rem;
    font-weight: 800;
    color: white;
    margin-bottom: 1rem;
}
.error-page h4 {
    font-family: "Staatliches", sans-serif;
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
    color: rgb(34, 248, 248);
    margin-bottom: 1rem;
}
.error-page span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.error-page span h3 {
    font-family: "Staatliches", sans-serif;
    font-size: 1.5rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
    margin-bottom: 1rem;
}
.error-page span .back-to {
    font-size: 3rem;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.error-page span .back-to:hover {
    color: rgb(34, 248, 248);
}

  @media all and (max-width: 320px) {
    .error-page h1 {
        font-size: 5rem;
    }
    .error-page h2 {
        font-size: 2.5rem;
    }
    .error-page h4 {
        font-size: 1.5rem;
    }
    .error-page span h3 {
        font-size: 1rem;
    }
    .error-page span .back-to {
        font-size: 2rem;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
  }