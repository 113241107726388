@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
.our-work {
  width: calc(100% -10rem);
  display: flex;
  margin: 5rem 5rem;
}
.our-work_container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.our-work_container-row {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;
}

.our-work_container-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}
.our-work_container-item:last-of-type {
  margin-right: 0rem;
}
.our-work_container-item h2 {
  position: relative;
  font-family: "Staatliches", sans-serif;
  font-size: 10rem;
}
.our-work_container-item h2::after {
  content: "CHAPTER";
  font-family: "Staatliches", sans-serif;
  font-size: 2rem;
  color: rgb(34, 248, 248);
}
.our-work_container-item img {
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: 40rem;
  box-shadow: 0px 0px 0px 0px rgba(34, 248, 248, 0.8);
  transition: box-shadow 0.2s ease-in-out;
}

.our-work_container-item img:hover {
  box-shadow: 0px 5px 10px 10px rgba(34, 248, 248, 0.8);
}

.our-work_container-item h4 {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 1.5rem;
  font-family: "Staatliches", sans-serif;
  text-transform: uppercase;
}

/* player container */
.our-work_container-item .player_wrapper {
  margin-top: 1rem;
  width: 30rem;
  height: 20rem;
  box-shadow: 0px 0px 0px 0px rgba(34, 248, 248, 0.8);
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
}

.our-work_gap {
  display: none;
  margin: 3rem 0;
  width: 100%;
  height: 0px;
  border-radius: 100%;
  background: rgba(34, 248, 248, 0.8);
  box-shadow: 10px 10px 10px 10px rgba(34, 248, 248, 0.8);
}

/* MEDIA QUERIES */

@media screen and (min-width: 1250px) and (max-width: 1649px) {
  .our-work_container-item h2 {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1249px) {
  .our-work_container-item img {
    width: 25rem;
    height: 35rem;
  }

  .our-work_container-item .player_wrapper {
    width: 25rem;
    height: 20rem;
  }

  .our-work_container-item h2 {
    position: relative;
    font-family: "Staatliches", sans-serif;
    font-size: 5.5rem;
  }

  .our-work_container-item h4 {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.5rem;
    font-family: "Staatliches", sans-serif;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .our-work_container {
    flex-direction: column;
  }
  .our-work_container-row {
    flex-direction: column;
    margin-bottom: 0rem;
  }
  .our-work_container-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 0;
  }
  .our-work_container-item img {
    width: 30rem;
    height: 40rem;
  }

  .our-work_container-item .player_wrapper {
    width: 30rem;
    height: 20rem;
  }
  .our-work_gap {
    display: block;
  }

  .our-work_container-item h2 {
    position: relative;
    font-family: "Staatliches", sans-serif;
    font-size: 8rem;
  }
  .our-work_container-item h2::after {
    font-size: 1.5rem;
  }

  .our-work_container-item h4 {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.5rem;
    font-family: "Staatliches", sans-serif;
    text-transform: uppercase;
  }
}
@media all and (min-width: 480px) and (max-width: 767px) {
  .our-work_container {
    flex-direction: column;
  }
  .our-work_container-row {
    flex-direction: column;
    margin-bottom: 0rem;
  }
  .our-work_container-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 0;
  }
  .our-work_container-item img {
    width: 25rem;
    height: 35rem;
  }
  .our-work_container-item .player_wrapper {
    width: 25rem;
    height: 15rem;
  }
  .our-work_gap {
    display: block;
    width: 100%;
  }
  .our-work_container-item h2 {
    position: relative;
    font-family: "Staatliches", sans-serif;
    font-size: 6rem;
  }
  .our-work_container-item h2::after {
    font-size: 1.5rem;
  }
  .our-work_container-item h4 {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.5rem;
    font-family: "Staatliches", sans-serif;
    text-transform: uppercase;
  }
}
@media all and (min-width: 280px) and (max-width: 479px) {
  .our-work_container {
    flex-direction: column;
  }
  .our-work_container-row {
    flex-direction: column;
    margin-bottom: 0rem;
  }
  .our-work_container-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 0;
  }
  .our-work_container-item img {
    width: 15rem;
    height: 25rem;
  }

  .our-work_container-item .player_wrapper {
    width: 15rem;
    height: 12rem;
  }
  .our-work_gap {
    display: block;
    width: 100%;
  }
  .our-work_container-item h2 {
    position: relative;
    font-family: "Staatliches", sans-serif;
    font-size: 4rem;
  }
  .our-work_container-item h2::after {
    font-size: 1rem;
  }
  .our-work_container-item h4 {
    text-align: center;
    margin-top: 1.5rem;
    font-size: 1rem;
    font-family: "Staatliches", sans-serif;
    text-transform: uppercase;
  }
}
