.find-us {
  display: flex;
  margin: 0rem 5rem;
  width: calc(100% - 10rem);
}

.find-us_container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.find-us_container h2 {
  font-size: 7rem;
  font-family: "Staatliches", sans-serif;
  margin-bottom: 2rem;
}
/* hided headings to reveal after going 1023px */
.animation-flex_container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.animation-flex_container::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: -10%;
  width: 100%;
  height: 3px;
  background-color: rgb(34, 248, 248);
  filter: blur(2px);
  display: none;
}

.find-us_container h1 {
  font-size: 11rem;
  font-family: "Staatliches", sans-serif;
  margin-bottom: 0rem;
  line-height: 7.5rem;
  display: none;
}
.find-us_container h3 {
  font-size: 7rem;
  font-family: "Staatliches", sans-serif;
  margin-bottom: 0rem;
  line-height: 7.5rem;
  color: rgb(34, 248, 248);
  display: none;
}

.find-us_container h2 span {
  color: rgb(34, 248, 248);
}

.find-us-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0 1rem 0;
}
.find-us-item h4 {
  font-size: 3rem;
  font-family: "Staatliches", cursive;
}

.find-us-item a {
  text-decoration: none;
  color: rgb(34, 248, 248);
  font-size: 5rem;
  transition: color 0.2s ease-in-out;
  margin-top: 1rem;
}

.find-us-item a:link,
.find-us-item a:hover,
.find-us-item a:focus,
.find-us-item a:active {
  text-decoration: none;
  color: white;
}

.find-us-spot {
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(100%);
  transition : box-shadow 0.2s ease-in-out;
  border-radius: 50%;
  padding: 1rem;
}

.find-us-spot:hover {
box-shadow: 4px 2px 2px 2px white;
}

.find-us-spot img {
  width: 8rem;
  height: 8rem;
  margin-bottom: 1rem;
}

.find-us_spot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.find-us-spot h5 {
  font-weight: 600;
  font-size: 0.8rem;
}

/* FORM */

.find-us-item input,
.find-us-item textarea {
  background: none;
  resize: none;
  font-size: 1rem;
  font-family: sans-serif;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid white;
  transition: border-bottom 0.2s ease-in-out;
}

.find-us-item input::placeholder,
.find-us-item .text-input::placeholder {
  font-size: 0.8rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.561);
}
.find-us-item input:focus,
.find-us-item .text-input:focus {
  outline: none;
  border-bottom: 2px solid rgb(34, 248, 248);
}

.find-us-item .name-input {
  width: 100%;
  height: 3rem;
  margin: 1rem 0;
}

.find-us-item .text-input {
  width: 100%;
  height: 10rem;
  margin-bottom: 1rem;
}
.find-us-item .form-button {
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid white;
  width: 6rem;
  height: 3rem;
  letter-spacing: 2px;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  font-weight: 800;
  font-family: sans-serif;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}

.find-us-item .form-button:hover {
  background: rgb(34, 248, 248);
  border: none;
  padding: 0.5rem;
  color: black;
  border-bottom: 1px solid white;
}

.find-us-item form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: 2px 2px 4px 2px rgb(34, 248, 248); */
  border: 2px solid rgb(34, 248, 248);
  border-radius: 10px;
  padding: 2rem;
}
/* message sented for animation */
.find-us-item .message-ok {
  opacity: 0;
  margin-top: 0.5rem;
  font-size: 1rem;
  color: rgb(49, 255, 93);
  font-weight: 800;
  transition: opacity 0.2s ease-in-out;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1650px) {
}

@media screen and (min-width: 1250px) and (max-width: 1649px) {
  .find-us {
    display: flex;
    margin: 5rem 2rem;
    width: calc(100% - 4rem);
  }
  .find-us_container h2 {
    font-size: 6rem;
    font-family: "Staatliches", sans-serif;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1249px) {
  .find-us {
    display: flex;
    margin: 5rem 0rem;
    width: 100%;
  }
  .find-us_container h2 {
    font-size: 5.2rem;
    font-family: "Staatliches", sans-serif;
    margin-bottom: 1rem;
  }
  .find-us-item h4 {
    font-size: 2rem;
  }
  .find-us-spot img {
    width: 6rem;
    height: 6rem;
    margin-bottom: 1rem;
  }
  .find-us-item {
    margin: 2rem 0rem;
  }
  .find-us-spot h5 {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .find-us_container h2 {
    display: none;
  }
  .find-us_container h1 {
    display: block;
    transform: skewX(8deg);
  }
  .animation-flex_container::before {
    display: block;
  }
  .find-us_container h3 {
    display: block;
  }
  .find-us_container .margin-bottom {
    margin-bottom: 2rem;
  }

  .find-us-item h4 {
    font-size: 2rem;
  }
  .find-us-spot img {
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
  }
  .find-us-item {
    margin: 1rem 0rem;
  }
  .find-us-spot h5 {
    font-size: 0.5rem;
    font-weight: 800;
  }

  /* FORM */
  .find-us-item .name-input {
    width: 100%;
    height: 2rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .find-us-item .text-input {
    width: 100%;
    height: 8rem;
    margin-bottom: 2rem;
  }
  .find-us-item .form-button {
    width: 4rem;
    height: 2rem;
    font-size: 0.6rem;
  }

  .find-us-item input,
  .find-us-item .text-input {
    background: none;
    font-size: 0.8rem;
  }

  .find-us-item input::placeholder,
  .find-us-item .text-input::placeholder {
    font-size: 0.6rem;
  }
}

@media all and (min-width: 480px) and (max-width: 767px) {
  .find-us {
    display: flex;
    margin: 5rem 2rem;
    width: calc(100% - 4rem);
  }
  .find-us_container h2 {
    display: none;
  }
  .find-us_container h1 {
    display: block;
    font-size: 7.5rem;
    line-height: 5.5rem;
    transform: skewX(12deg);
  }
  .find-us_container h3 {
    display: block;
    font-size: 5rem;
    line-height: 5.5rem;
  }
  .animation-flex_container::before {
    display: block;
  }
  .find-us_container .margin-bottom {
    margin-bottom: 2rem;
  }

  .find-us-item h4 {
    font-size: 1.3rem;
  }
  .find-us-spot img {
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
  }
  .find-us-item {
    margin: 1rem 0rem;
  }
  .find-us-spot h5 {
    font-size: 0.28rem;
    font-weight: 800;
  }

  /* FORM */
  .find-us-item .name-input {
    width: 100%;
    height: 2rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .find-us-item .text-input {
    width: 100%;
    height: 8rem;
    margin-bottom: 2rem;
  }
  .find-us-item .form-button {
    width: 4rem;
    height: 2rem;
    font-size: 0.6rem;
  }

  .find-us-item input,
  .find-us-item .text-input {
    background: none;
    font-size: 0.8rem;
  }
  .find-us-item input::placeholder,
  .find-us-item .text-input::placeholder {
    font-size: 0.6rem;
  }
}
@media all and (min-width: 280px) and (max-width: 479px) {
  .find-us {
    display: flex;
    margin: 4rem 0rem;
    width: 100%;
  }
  .find-us_container h2 {
    display: none;
  }
  .find-us_container h1 {
    display: block;
    font-size: 5.4rem;
    line-height: 4rem;
    transform: skewX(10deg);
  }
  .find-us_container h3 {
    display: block;
    font-size: 3.5rem;
    line-height: 4rem;
  }
  .find-us_container .margin-bottom {
    margin-bottom: 1.5rem;
  }
  .animation-flex_container::before {
    display: block;
  }

  .find-us-item h4 {
    font-size: 1rem;
  }
  .find-us-spot img {
    width: 2rem;
    height: 2rem;
    margin-bottom: 1rem;
  }
  .find-us-item {
    margin: 0.5rem 0rem;
  }
  .find-us-item a {
    font-size: 2rem;
  }
  .find-us-spot h5 {
    font-size: 0.5rem;
  }
  .find-us-spot {
    margin: 0.5rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* FORM */
  .find-us-item form {
    padding: 0.5rem;
  }
  .find-us-item .name-input {
    width: 80%;
    height: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .find-us-item .text-input {
    width: 80%;
    height: 6rem;
    margin-bottom: 1rem;
  }
  .find-us-item .form-button {
    width: 2.5rem;
    height: 1.5rem;
    padding: 0.1rem;
    margin-bottom: 0.5rem;
    font-size: 0.4rem;
    border-radius: 15%;
    border: 1px solid white;
    color: white;
  }

  .find-us-item input,
  .find-us-item .text-input {
    background: none;
    font-size: 0.6rem;
  }
  .find-us-item input::placeholder,
  .find-us-item .text-input::placeholder {
    font-size: 0.4rem;
  }
  .find-us-item .message-ok {
    opacity: 0;
    font-size: 0.8rem;
  }
}
