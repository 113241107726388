@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
}
::selection {
  background-color: rgb(34, 248, 248);
}
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: "Lato", sans-serif;
  color: white;
  background: black;
  box-sizing: border-box;
  cursor: none;
}
.custom-cursor {
  display: flex;
  z-index: 10000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 2px solid rgb(34, 248, 248);
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  transform: translate3d(0, 0, 0);
}
/*scrollbar
/* width */
::-webkit-scrollbar {
  width: 12px;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(34, 248, 248);
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .custom-cursor {
    display: none !important;
  }

  *,
*::after,
*::before {
  cursor: auto;
}
  body {
    cursor: auto !important;
  }

  a:hover,
  .link-style:hover{
    cursor: pointer !important;
  }
}
