.nav{
    height:10rem;
    width: 100%;
    margin-bottom: 3rem;
    
}
/* nav  of interviews and interview section */
.nav-interviews{
    height:10rem;
    width: 100%;
    margin-bottom: 1rem;
}

.nav-container{
    width:100%;
    padding: 0 2rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;    
    box-shadow:0 10px 20px 0px rgb(255, 255, 255);
}
/* nav container of interviews and interview section */

.nav-container_interviews{
    width:100%;
    padding: 0 8rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* nav menu of interviews and interview section */
.nav-menu_interviews{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav img{
    width: 15%;
    height: 80%;
    margin: 1.5rem 0;

}
/* nav img logo of interviews and interview section */
.nav-interviews img{
    width: 20%;
    height: 80%;
    margin: 1.5rem 0;
}

.nav .link-style{
    font-family: 'Staatliches', sans-serif;
    font-size: 1.6rem;
    position: relative;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 2rem;
    padding-bottom: .2rem;
}


.nav .link-style::after{
    content: '';
    position: absolute;
    bottom:0;
    left: 0;
    width: 0%;
    height:2px;
    background-color: white;
    color: white;
    transition: width .2s ease-in-out;
}

.nav .link-style:hover::after{
    width: 100%;
}

.nav .link-style span{
    color:rgb(34, 248, 248);
}

.toggle-menu{
    color: white;
    font-size: 2.5rem;
    cursor: none;
    display: none;
    background-color: transparent;
    text-decoration: none;
    border: none;
    z-index:11;    
}

.toggle-menu:focus {
    outline: none;
    box-shadow: none;
  }


#logo{
    font-weight: 700;
    font-size: 2rem;
}
/* home link interviews and interview section */
.home-link{
    position: relative;
    font-family:'Staatliches', sans-serif;
    font-size: 3rem;
    color:white; 
    padding: .6rem;
    padding-bottom: 0rem;
    border-radius: 50%;
    transition: all .2s ease-in-out;
}
.home-link::after{
    content: 'HOME';
    position: absolute;
    bottom: -10%;
    right: 25%;
    color:rgb(34, 248, 248);
    font-family:'Staatliches', sans-serif;
    font-size: 1.2rem;
    transition: all .2s ease-in-out;
}
/* MEDIA QUERIES */
@media screen and (min-width: 1651px) {
    nav{
        margin-bottom: 10rem;    
    }
}

@media screen and (max-width: 1650px) {
    .nav .link-style{
        font-size: 1.3rem;   
    }
}

    @media screen and (min-width: 1440px)and (max-width:1649px) {

        .nav .link-style{
            font-size: 1.3rem;   
        }
        .nav img{
            width: 15%;
            height: 60%;
        }
    }

    @media screen and (min-width: 1024px)and (max-width:1439px) {
        nav{
            height:8rem;
            margin-bottom: 10rem;    
        }
        .nav .link-style{
            font-size: 1.3rem;   
        }
        .nav img{
            width: 15%;
            height: 60%;
        }
        .home-link{
            font-size: 3rem;
        }
        .nav-interviews img{
            width: 25%;
            height: 60%;
        }
        .home-link{
            font-size: 2.3rem;
        }
        .home-link::after{
            bottom: -10%;
            right: 20%;
            font-size: 1rem;
        }
    }

    @media screen and (min-width: 768px)and (max-width:1023px) {

        .nav-menu{
            display: none;
        }
        .toggle-menu{
            display: block;
            font-size: 2rem;    
        }
        .nav-container{
            box-shadow:none;
            flex-direction: row;
        }
        .nav img{
            width: 30%;
            height: 60%;
        }
        .nav-interviews img{
            width: 35%;
            height: 60%;
        }
        .home-link{
            font-size: 2rem;
        }
        .home-link::after{
            bottom: -10%;
            right: 20%;
            font-size: 1rem;
        }
    }
    @media all and (min-width: 480px) and (max-width: 767px) {
        .nav-menu{
            display: none;
        }
        .toggle-menu{
            display: block;
        }
        .nav-container{
            box-shadow:none;
            flex-direction: row;
        }
        .nav-container_interviews{
            width:100%;
            padding: 0 2rem;
            
        }
        .toggle-menu{
            font-size: 2rem;
        }
        .nav img{
            width: 30%;
            height: 60%;
        }
        .nav-interviews img{
            width: 45%;
            height: 60%;
        }
        .home-link{
            font-size: 2rem;
        }
        .home-link::after{
            bottom: -10%;
            right: 20%;
            font-size: 1rem;
        }
     }
     @media all and (min-width: 280px) and (max-width: 479px) {
         .nav-interviews{
             margin-bottom: 1rem;
         }
        .nav-menu{
            display: none;
        }
        .toggle-menu{
            display: block;
        }
        .nav-container{
            box-shadow:none;
            flex-direction: row;
        }
        .nav-container_interviews{
            width:100%;
            padding: 0 2rem;
            
        }
        .toggle-menu{
            font-size: 1rem;
        }
        .nav img{
            width: 30%;
            height: 60%;
        }
        .nav-interviews img{
            width: 55%;
            height: 60%;
        }
        .home-link{
            font-size: 1.3rem;
        }
        .home-link::after{
            bottom: -10%;
            right: 25%;
            font-size: .6rem;
        }

      }



 