.about-us {
  width: calc(100%-10rem);
  height: auto;
  position: relative;
  margin: 10rem 5rem 5rem 5rem;
}

.about-us .background-photo {
  position: absolute;
  width: 25vw;
  height: 70vh;
  top: 40%;
  right: 20%;
  filter: drop-shadow(10px 10px 20px rgb(34, 248, 248)) opacity(0.3);
}

.about-us_container {
  width: 100%;
  display: grid;
  place-content: center;
}

.about-us p {
  font-family: sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: .5px;
  margin: 0.2rem 0;
}
.about-us_bio {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.about-us_bio h4 {
  font-family: sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0.2rem 0;
}
.about-us_bio h5 {
  font-family: sans-serif;
  font-size: 0.8rem;
  margin-top: 1rem;
  font-weight: 500;
  color: rgb(34, 248, 248);
}

.about-us span:first-of-type {
  display: block;
  font-family: "Staatliches", sans-serif;
  font-size: 9.5rem;
  position: relative;
  color: white;
  transition: opacity 0.1s linear;
}

.about-us .line {
  margin: 0.6rem 0;
  width: 100%;
  height: 2px;
  background-color: white;
}

.about-us_change {
  display: flex;
  margin-top: 0.5rem;
  pointer-events: none;
}

.about-us_change img {
  height: 12rem;
  width: 15rem;
  opacity: 0;
}

.about-us_change span {
  display: block;
  font-family: "Staatliches", sans-serif;
  font-size: 10rem;
  position: relative;
  color: white;
  margin-left: 0.5rem;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1650px) {
}

@media screen and (min-width: 1440px) and (max-width: 1649px) {
}

@media screen and (min-width: 1250px) and (max-width: 1439px) {
  .about-us {
    margin: 7rem 5rem 5rem 5rem;
  }
  .about-us .background-photo {
    width: 25vw;
    height: 70vh;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1249px) {
  .about-us {
    margin: 5rem 5rem 5rem 5rem;
  }
  .about-us_change img {
    height: 10rem;
    width: 13rem;
  }
  .about-us_change span {
    font-size: 8rem;
  }
  .about-us_bio h4 {
    font-size: 1rem;
  }
  .about-us_bio h5 {
    font-size: 0.6rem;
    margin-top: 0.8rem;
  }
  .about-us span:first-of-type {
    font-size: 8rem;
  }
  .about-us p {
    font-size: 0.8rem;
  }

  .about-us .background-photo {
    width: 35vw;
    height: 80vh;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .about-us {
    margin: 1rem 5rem 5rem 1rem;
  }
  .about-us_change img {
    height: 9rem;
    width: 12rem;
  }
  .about-us_change span {
    font-size: 7rem;
  }
  .about-us_bio h4 {
    font-size: 0.8rem;
  }
  .about-us_bio h5 {
    font-size: 0.5rem;
    margin-top: 0.7rem;
  }
  .about-us span:first-of-type {
    font-size: 7rem;
  }
  .about-us p {
    font-size: 0.7rem;
  }

  .about-us .background-photo {
    width: 40vw;
    height: 70vh;
    top: 40%;
  }
  .about-us .line {
    width: 98%;
  }
}
@media all and (min-width: 480px) and (max-width: 767px) {
  .about-us {
    width: calc(100%-2rem);
    height: auto;
    position: relative;
    margin: 2rem 1rem;
  }
  .about-us_change img {
    height: 7rem;
    width: 8.5rem;
  }
  .about-us_change span {
    font-size: 7rem;
    margin-left: 0.2rem;
  }
  .about-us_bio h4 {
    font-size: 0.6rem;
  }
  .about-us_bio h5 {
    font-size: 0.65rem;
    margin-top: 0.65rem;
  }
  .about-us span:first-of-type {
    font-size: 5.4rem;
  }
  .about-us p {
    font-size: 0.55rem;
  }
  .about-us .line {
    width: 95%;
  }

  .about-us .background-photo {
    width: 50vw;
    height: 70vh;
    top: 70%;
    right: 30%;
  }
}
@media all and (min-width: 360px) and (max-width: 479px) {
  .about-us {
    width: calc(100%-2rem);
    height: auto;
    position: relative;
    margin: 2rem 1rem;
  }
  .about-us_change img {
    height: 4rem;
    width: 6rem;
  }
  .about-us_change span {
    font-size: 3.5rem;
    margin-left: 0.2rem;
  }
  .about-us_bio h4 {
    font-size: 0.4rem;
    margin: 0.1rem 0;
  }
  .about-us_bio h5 {
    font-size: 0.2rem;
    margin-top: 0.2rem;
  }
  .about-us span:first-of-type {
    font-size: 3.5rem;
  }
  .about-us p {
    font-size: 0.4rem;
    margin: 0.1rem 0;
  }
  .about-us .line {
    width: 93%;
    margin: 0.2rem 0;
    height: 1px;
  }
  .about-us .background-photo {
    width: 50vw;
    height: 40vh;
    top: 70%;
    right: 20%;
  }
}
@media all and (min-width: 280px) and (max-width: 359px) and (min-height: 800px) {
  .about-us {
    width: calc(100%-0.5rem);
    height: auto;
    position: relative;
    margin: 2rem 0.25rem;
  }
  .about-us_change img {
    height: 4rem;
    width: 6rem;
  }
  .about-us_change span {
    font-size: 3rem;
    margin-left: 0.2rem;
  }
  .about-us_bio h4 {
    font-size: 0.3rem;
    margin: 0.1rem 0;
  }
  .about-us_bio h5 {
    font-size: 0.2rem;
    margin-top: 0.2rem;
  }
  .about-us span:first-of-type {
    font-size: 2.5rem;
  }
  .about-us p {
    font-size: 0.3rem;
    margin: 0.1rem 0;
  }
  .about-us .line {
    width: 88%;
    margin: 0.2rem 0;
    height: 1px;
  }
  .about-us .background-photo {
    width: 50vw;
    height: 40vh;
    top: 70%;
    right: 20%;
  }
}

@media only screen and (min-device-width: 300px) and (max-device-width: 428px) and (min-device-height: 640px) and (max-device-height: 800px) and (-webkit-device-pixel-ratio: 1),
  only screen and (min-device-width: 300px) and (max-device-width: 428px) and (min-device-height: 640px) and (max-device-height: 800px) and (-webkit-device-pixel-ratio: 2),
  only screen and (min-device-width: 300px) and (max-device-width: 428px) and (min-device-height: 640px) and (max-device-height: 860px) and (-webkit-device-pixel-ratio: 3),
  only screen and (min-device-width: 300px) and (max-device-width: 428px) and (min-device-height: 640px) and (max-device-height: 800px) and (-webkit-device-pixel-ratio: 2.75),
  only screen and (min-device-width: 300px) and (max-device-width: 428px) and (min-device-height: 640px) and (max-device-height: 800px) and (-webkit-device-pixel-ratio: 2.8),
  only screen and (min-device-width: 300px) and (max-device-width: 428px) and (min-device-height: 640px) and (max-device-height: 800px) and (-webkit-device-pixel-ratio: 4) {
  .about-us {
    width: 100%;
    height: auto;
    position: relative;
    margin: 2rem 0;
    padding: 0 1rem;
  }
  .about-us_change img {
    height: 5rem;
    width: 6rem;
  }
  .about-us_change span {
    font-size: 3.8rem;
    margin-left: 0.2rem;
  }
  .about-us_bio h4 {
    font-size: 0.4rem;
    margin: 0.1rem 0;
  }
  .about-us_bio h5 {
    font-size: 0.5rem;
    margin-top: 0.2rem;
  }
  .about-us span:first-of-type {
    font-size: 3.8rem;
  }
  .about-us p {
    font-size: 0.4rem;
    margin: 0.1rem 0;
  }
  .about-us .line {
    width: 88%;
    margin: 0.1rem 0;
    height: 1px;
  }
  .about-us .background-photo {
    width: 50vw;
    height: 40vh;
    top: 70%;
    right: 20%;
  }
}
