.mobile-menu-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 25rem;
  width: 10rem;
  background: rgba(0, 0, 0, 1);
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: 10px;
  border-top-right-radius: 1px;
  border: 2px solid white;
}

.mobile-item {
  font-family: "Staatliches", sans-serif;
  transition: all 0.2s ease-in-out;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.6rem;
  padding: 0.4rem;
  border: 1px solid transparent;
}

.mobile-item span {
  color: rgb(34, 248, 248);
  font-size: 1.6rem;
  transition: all 0.2s ease-in-out;
}

.mobile-item:hover {
  color: rgb(34, 248, 248);
  border: 1px solid white;
}
.mobile-item:hover span {
  color: white;
}
.mobile-item:hover .custom-cursor {
  border: 2px solid black;
}
@media screen and (min-width: 1651px) {
  .mobile-menu-container {
    display: none;
  }
}

@media screen and (max-width: 1650px) {
  .mobile-menu-container {
    display: none;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1649px) {
  .mobile-menu-container {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .mobile-menu-container {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mobile-menu-container {
    display: flex;
  }
}
@media all and (min-width: 480px) and (max-width: 767px) {
  .mobile-menu-container {
    display: flex;
    height: 20rem;
    width: 10rem;
  }
  .mobile-item,
  .mobile-item span {
    font-size: 1.1rem;
  }
}
@media all and (min-width: 280px) and (max-width: 479px) {
  .mobile-menu-container {
    display: flex;
    height: 15rem;
    width: 8rem;
  }
  .mobile-item,
  .mobile-item span {
    font-size: 1rem;
  }
}
@media all and (max-width: 279) {
  .mobile-menu-container {
    display: flex;
  }
}
