footer{
    width: 100%;
    height: auto;
    font-family: 'Staatliches', sans-serif;
    border-top:2px solid white;  
}

.footer-container{
    width: 100%;
    height: 100%;
    padding: 0 4rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.footer-container_item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 6rem;
}
/* last item 0 to the right to center problem*/
.footer-container_item:last-of-type{
    margin-right: 0;
}
.footer-container_item img{
    width: 15rem;
    height: 80%;
    cursor: none;
    margin: 1.5rem 0;    
}
.footer-container_item a{
    text-decoration: none;
}
.footer-container_item-nav{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 0;
}
/* scroll to top icon */
.footer-container_item-nav .link-style{
    font-family: 'Staatliches', cursive;
    font-size: 3rem;
    position: relative;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 2rem;
    padding-bottom: .2rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
    animation: upnDown 1.5s infinite linear;
}
/* scroll to top icon mini animation */

@keyframes upnDown{
    0%{
        transform: translateY(0px); 
    }
    50%{
        transform: translateY(2px); 
    }
    75%{
        transform: translateY(1px);   
    }
    100%{
        transform: translateY(0px);       
    }
}

.footer-container_item-nav .link-style:hover{
    font-family: 'Staatliches', cursive;
    position: relative;
    color: rgb(34, 248, 248);
    animation: none;
    transition: all .2s ease-in-out;
}
.footer-container_item-nav .link-style::after{
    content: 'TO TOP';
    position: absolute;
    bottom:0;
    left: 15%;
    font-size: 1rem;
    color: white;
    transition: width .2s ease-in-out;
}
/* /////END scroll to top*/

/* designed by...text*/
.footer-container_item p{
    font-size: .8rem;
    letter-spacing: 2px;   
}

/* social links */
.footer-container_item-social{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.footer-container_item-social a,
.footer-container_item-social h4{
    font-size: 1.5rem;
    color: white;
    transition: color .2s ease-in-out;
    font-weight: 500;
}

.footer-container_item-social h4{
    margin-bottom: .5rem;
}

.footer-container_item-social a:hover{
    color: rgb(34, 248, 248);
}
/* ///////END of social links */

/* MEDIA QUERIES *//* MEDIA QUERIES *//* MEDIA QUERIES *//* MEDIA QUERIES */
/* MEDIA QUERIES *//* MEDIA QUERIES *//* MEDIA QUERIES *//* MEDIA QUERIES */

@media screen and (min-width: 1651px) {
}
@media screen and (max-width: 1650px) {
}
@media screen and (min-width: 1440px)and (max-width:1649px) {
}
@media screen and (min-width: 1024px)and (max-width:1439px) {

.footer-container_item-social a,
.footer-container_item-social h4{
   font-size: 1.2rem;   
}
.footer-container_item p{
   font-size: .6rem;
   letter-spacing: 2px;           
}
}
 @media screen and (min-width: 768px)and (max-width:1023px) {
.footer-container_item-social a,
.footer-container_item-social h4{
    font-size: 1rem;   
}
.footer-container_item p{
    font-size: .6rem;
    letter-spacing: 2px;   
}
.footer-container_item{
    margin-right: 3rem;
}
.footer-container_item-nav .link-style{
    font-size: 2.5rem;
}
.footer-container_item-nav .link-style::after{
    bottom:0;
    left: 20%;
    font-size: .7rem;
}
}
@media all and (min-width: 480px) and (max-width: 767px) {
footer{
    height:auto;
}
.footer-container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}
.footer-container_item-social a,
.footer-container_item-social h4{
    font-size: .8rem;
    display: none;   
}
.footer-container_item-social h4{
    display: none; 
}
.footer-container_item p{
    font-size: .4rem;
    letter-spacing: 1px;   
}
.footer-container_item{
    margin-right: 1rem;
}
/* display none to social links */
.footer-container_item:last-of-type{
    display: none;
}
.footer-container_item img{
    width: 14rem;
    height: 5rem;
    margin: 0; 
}
.footer-container_item-nav .link-style{
    font-size: 2rem;
}
.footer-container_item-nav .link-style::after{
    bottom:0;
    left: 20%;
    font-size: .5rem;
}
}
@media all and (min-width: 280px) and (max-width: 479px) {
footer{
    height: auto;
}
.footer-container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}
.footer-container_item-social a,
.footer-container_item-social h4{
    font-size: .8rem;
    display: none;   
}
.footer-container_item-social h4{
    display: none; 
}
.footer-container_item p{
    font-size: .3rem;
    letter-spacing: 1px;
    margin: .5rem 0;   
}
.footer-container_item{
    margin:0;
}
.footer-container_item:last-of-type{
    display: none;
}
.footer-container_item img{
    display: block;
    width: 9rem;
    height: 3rem;
    margin: 0; 
}
.footer-container_item-nav .link-style{
    font-size: 2rem;
}
.footer-container_item-nav .link-style::after{
    bottom:0;
    left: 20%;
    font-size: .5rem;
}
}


    