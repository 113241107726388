.interviews{
    width: calc(100%-10rem);
    margin:5rem 5rem;
}

.interviews h1 span{
    display: inline-block;
    position: relative;
    color:rgb(34, 248, 248);
    font-size: 10rem;
    transform-origin: 50% 50%;     
}
/* background effect + sign ,possible to make problems on resposivness so we modify it in mq */
.interviews h1 span::after{
    content: "+";
    position: absolute;
    bottom: -500%;
    left:30rem;
    font-size: 40rem;
    color: rgba(34, 248, 248,0.2);
    filter: blur(15px);
    animation: upDown 2s linear infinite;
    transform-origin: 50% 50%;
    z-index: -1;
}
@keyframes upDown{
    0%{
        transform: translateY(0px);
        
    }
    50%{
        transform: translateY(10px);
        color: rgba(255, 255, 255, .2)
        
        
    }
    75%{
        transform: translateY(5px);
        color: rgba(255, 255, 255, 0.2)
        
    }

    100%{
        transform: translateY(0px);
        color: rgba(34, 248, 248,0.2);
           
    }
}

.interviews-container{
    width: 100%;
    display:flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    margin-bottom:10rem;
}


.interviews-container .interviews-item{
    position: relative;
    width: 100%;
    padding: 0 10rem;
    display: flex;
    justify-content:flex-start;
    align-items:baseline;
    flex-direction: row;
    line-height: 1;
    margin: 1rem 0;
}
/* blue highlight on click fix */
.interviews-container .interviews-item:focus{
    outline: none;
    -webkit-tap-highlight-color: transparent;

}

.interviews-container h1{
    font-family: 'Staatliches', sans-serif;
    font-size: 8rem;
    letter-spacing: 2px;
    margin-bottom: 3rem; 
}
.interviews-container .interviews-item h2,
.interviews-container .interviews-item .interviewed{  
    text-decoration: none;

    font-family: 'Staatliches', sans-serif;
    font-weight: 800;
    color: white;
    font-size: 6rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    letter-spacing: 4px;
    transition: all .4s ease-in-out;
    position: relative;
    cursor: pointer;

}

.interviews-container .interviews-item h3{  
    font-family: 'Staatliches', sans-serif;
    font-size: 2rem;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: black;
    text-shadow: 1px 1px 0 #ffffff, -1px 1px 0 #ffffff,
    1px -1px 0 #ffffff, -1px -1px 0 #ffffff;
    transition: all .4s ease-in-out;   
}


/* line effect on hover of links */
.interviews-container .interviews-item:hover h2,
.interviews-container .interviews-item:hover .interviewed{
    color: black;
    text-shadow: 1px 1px 0 #ffffff, -1px 1px 0 #ffffff,
    1px -1px 0 #ffffff, -1px -1px 0 #ffffff;

}
.interviews-container .interviews-item:hover h3{
    color: white;
    text-shadow: 1px 1px 0 #000000, -1px 1px 0 #000000,
    1px -1px 0 #000000, -1px -1px 0 #000000;  
    
}

 .interviews-item h2::after,
 .interviews-item .interviewed::after{
    content: '';
    position: absolute;
    bottom:0;
    left: 0;
    width: 0%;
    height:0px;
    background-color: transparent;
    color: white;
    transition: width .2s ease-in-out;
}

 .interviews-item h2:hover::after,
 .interviews-item .interviewed:hover::after{
    width: 100%;
    box-shadow: 0px 5px 5px 2px rgb(34, 248, 248);
    background-color: transparent;
    height:0px;
    
}
/* MEDIA QUERIES */
@media screen and (min-width: 1651px) {

}

@media screen and (max-width: 1650px) {

}

@media screen and (min-width: 1440px)and (max-width:1649px) {
    .interviews{
        width: calc(100%-4rem);
        margin:5rem 2rem;
    }
.interviews h1 span,
.interviews h1{
    font-size: 9rem;   
    }
.interviews-container .interviews-item .interviewed{  
    font-size: 6rem;
}
.interviews-container .interviews-item h3{  
    font-size: 2rem;
}
}

@media screen and (min-width: 1250px)and (max-width:1439px) {
    .interviews{
        width: calc(100%-6rem);
        margin:5rem 3rem;
    }
    .interviews-container .interviews-item{
        padding: 0 6rem;
        margin: 1rem 0;
    }
.interviews h1 span,
.interviews h1{
    font-size: 7rem;   
    }
.interviews-container .interviews-item .interviewed{  
    font-size: 4rem;
    }
.interviews-container .interviews-item h3{  
    font-size: 1.5rem;
    }
}
@media screen and (min-width: 1024px)and (max-width:1249px) {
    .interviews{
        width: calc(100%-6rem);
        margin:5rem 0rem;
    }
    .interviews-container .interviews-item{
        padding: 0 6rem;
        margin: 1rem 0;
    }
.interviews h1 span,
.interviews h1{
    font-size: 6rem;   
    }
.interviews-container .interviews-item .interviewed{  
    font-size: 4rem;
    }
.interviews-container .interviews-item h3{  
    font-size: 1.5rem;
    }
/* background effect + sign ,possible to make problems on resposivness so we modify it in mq */
    .interviews h1 span::after{
        left:20rem;
    }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
    .interviews{
        width: 100%;
        margin:5rem 0rem;
    }
    .interviews-container .interviews-item{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        margin: 2rem 0;
        padding: 0;
    }
    .interviews h1,
    .interviews h1 span{
        font-size: 5rem;   
        }
    .interviews-container .interviews-item .interviewed{  
        font-size: 4rem;
        }
    .interviews-container .interviews-item h3{  
        font-size: 1rem;
        color: white;
        text-shadow:none;
        transition: all .4s ease-in-out;
        margin-top: 1rem;   
        }
/* background effect + sign ,possible to make problems on resposivness so we modify it in mq */
        .interviews h1 span::after{
            left:18rem;
        }
}
@media all and (min-width: 480px) and (max-width: 767px) {
    .interviews{
        width: 100%;
        margin:5rem 0rem;
    }
    .interviews-container .interviews-item{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        margin: 2rem 0;
        padding: 0rem;
    }
    .interviews h1 span,
    .interviews h1 {
        font-size: 5rem;   
        }
    .interviews-container .interviews-item .interviewed{  
        font-size: 3rem;
        }
        .interviews-container .interviews-item h3{  
            font-size: .6rem;
            color: white;
            text-shadow:none;
            transition: all .4s ease-in-out;   
            margin-top: 1rem;  
            }
/* background effect + sign ,possible to make problems on resposivness so we modify it in mq */
            .interviews h1 span::after{
                left:5rem;
                bottom: -400%;
            }


}
@media all and (min-width: 280px) and (max-width: 479px) {
    .interviews{
        width: 100%;
        margin:1rem 0rem 2rem 0rem;
    }
    .interviews-container .interviews-item{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        margin: 2rem 0;
        padding: 0rem;
    }
    .interviews h1 span,
    .interviews h1 {
        font-size: 3rem;   
        }
    .interviews-container .interviews-item .interviewed{  
        font-size: 2rem;
        }
        .interviews-container .interviews-item h3{  
            font-size: .3rem;
            color: white;
            text-shadow:none;
            transition: all .4s ease-in-out;   
            margin-top: 1rem;  
            }
/* background effect + sign ,possible to make problems on resposivness so we modify it in mq */
            .interviews h1 span::after{
                left:0rem;
                bottom: -600%;
                font-size: 30rem;
            }
}


    

